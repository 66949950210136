.product-cart {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  position: relative;

  width: 250px;
  padding: 10px 0;
  box-sizing: border-box;
  background: #E8D368;

  box-shadow: 0 0 0 1px;
}

.product-cart img {
  height: 150px;
}

.product-cart .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.product-cart button {
  display: grid;
  place-items: center;

  position: absolute;
  top: 10px;
  right: 10px;

  width: 20px;
  height: 20px;
  border: None;
  border-radius: 50%;
  background: black;

  color: white;
  font-weight: bold;
  line-height: 0;

  box-shadow: 0 0 0 1px;
  transition: 0.3s;
}

.product-cart button:hover {
  box-shadow: 0 0 0 1px black, 0 0 0 2px white, 0 0 0 4px black;
}
