.header {
  position: sticky;
  top:0;

  padding: 10px 0;
  background: #2D2B63;

  text-align: center;
  font-family: sans-serif;
  font-size: 40px;
}

.header a {
  text-decoration: none;
  color: white;
}
