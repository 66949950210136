body {
  min-height: 100vh;
  margin: 0;
  background: #EEE;
}

#root {
  width: 100%;
  height: 100%;
}
