.menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  height: calc( 100vh - 65px );
}

@media (max-width: 700px) {
  .menu {
    flex-direction: column;
  }
}
