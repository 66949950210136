.product {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 250px;
  padding: 10px 0;
  box-sizing: border-box;
  background: #E8D368;

  box-shadow: 0 0 0 1px;
}

.product img {
  height: 150px;
}

.product .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.product input[type="number"] {
  width: 48px;
}

.product .info input[type="submit"] {
  border: None;
  box-shadow: 0 0 0 1px;
  transition: 0.3s;
}

.product input[type="submit"]:hover {
  box-shadow: 0 0 0 1px black, 0 0 0 2px white, 0 0 0 4px black;
}
