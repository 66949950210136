.card {
  display: grid;
  place-items: center;

  width: 300px;
  height: 300px;
  border: none;
  background: #E8DB97;

  font-size: 20px;
  font-family: sans-serif;
  text-decoration: none;
  color: black;

  transition: 0.5s;
  box-shadow: 0 0 0 1px;
  cursor: pointer;
}

.card:hover {
  background: #E8D15D;

  box-shadow: 0 0 0 1px, 0 0 0 4px white, 0 0 0 6px;
}

@media (max-width: 700px) {
  .card {
    width: 200px;
    height: 200px;
  }
}
