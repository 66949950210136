.carrito {
  --bar-gap: 20px;
  display: flex;
  flex-direction: column;
}

.carrito .search-bar {
  display: flex;
  justify-content: center;
  gap: var(--bar-gap);

  position: sticky;
  top: 65px;

  padding: 10px 0;
  background: #E8DB97;

  font-family: sans-serif;

  box-shadow: 0 0 0 1px black;
}

.carrito .button {
  display: grid;
  place-items: center;

  border: none;
  padding: 5px;
  background: white;

  font-size: 16px;
  text-decoration: none;
  color: black;

  box-shadow: 0 0 0 1px black;
  transition: 0.3s;
}

.carrito .button:hover {
  box-shadow: 0 0 0 1px black, 0 0 0 2px white, 0 0 0 4px black;
}

.carrito .return {
  background: black;

  color: white;
}

.carrito .products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  gap: 20px;

  margin: 20px;
}

.carrito .total {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .carrito .search-bar {
    flex-direction: column;
    align-items: center;
  }

  .carrito .search-bar form {
    flex-direction: column;
    gap: 10px;
  }

  .carrito .search-bar .return {
    order: 10;
  }
}
