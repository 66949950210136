.modal {
  display: grid;
  place-items: center;

  position: fixed;
  top: 0;

  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);

  font-family: sans-serif;
}

.modal>div {
  display: flex;
  gap: 20px;

  position: relative;

  width: 90%;
  max-width: 450px;
  padding: 50px;
  background: white;

  box-sizing: border-box;
}

.modal>div img {
  width: 150px;
  height: 150px;
  margin: auto;
}

.modal .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  margin: auto;
  font-size: 20px;
}

.modal .name {
  font-size: 25px;
  font-weight: bold;
}

.modal .close {
  position: absolute;
  top: 10px;
  right: 10px;

  width: 33px;
  height: 34px;
  border: none;
  background: black;

  font-size: 23px;
  text-align: center;
  color: white;

  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.modal .close:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 4px black;
}
