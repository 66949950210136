.captura {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: sans-serif;
}

.captura form {
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 10px;

  width: 90%;
  max-width: 400px;
  margin: 50px 0;
  padding: 20px;
  box-sizing: border-box;
  background: #E8DB97;

  box-shadow: 0 0 0 1px;
}

.captura form :last-child {
  grid-column: 2 span;
}

.captura .return {
  padding: 10px;
  background: black;

  text-decoration: none;
  color: white;

  transition: 0.3s;
}

.captura .return:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 3px black;
}
